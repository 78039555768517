<template>
  <section class="q-pa-md">
    <h1>Set password</h1>
    <q-form
      autofocus
      class="q-gutter-md"
      @submit.prevent="submit"
    >
      <q-field
        filled
        label="Email"
        stack-label
      >
        <template v-slot:control>
          <div class="self-center full-width no-outline">
            {{ email }}
          </div>
        </template>
      </q-field>
      <q-input
        v-model="innerValue.password"
        clearable
        label="Password"
        type="password"
      />
      <q-btn
        color="primary"
        label="Set password"
        type="submit"
      />
    </q-form>
  </section>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'

  const moduleName = 'auth'
  const { mapActions } = createNamespacedHelpers(
    moduleName,
  )

  export default {
    computed: {
      tokenPayload: {
        get () {
          try {
            return JSON.parse(
              atob(
                this.innerValue.token.split('.')[1].replace(
                  /-/g,
                  '+',
                ).replace(
                  /_/g,
                  '/',
                ),
              ),
            )
          } catch (error) {
            console.error(error)
            return {}
          }
        },
      },
      email: {
        get () {
          return this.$_.get(
            this.tokenPayload,
            'email',
          )
        },
      },
    },
    meta: {
      title: 'Set password',
    },
    methods: {
      ...mapActions(
        [
          'setPassword',
        ],
      ),
      async submit () {
        try {
          await this.setPassword(
            this.innerValue,
          )
        } catch (error) {
          return
        }

        await this.$router.push(
          `/${this.$route.params.locale}/sign-in`,
        )
      },
    },
    data () {
      return {
        innerValue: {
          token: this.$route.query.token,
        },
      }
    },
  }
</script>
